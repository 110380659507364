/* eslint-disable no-console */
import 'whatwg-fetch';
import domready from 'domready';

function embed(elem) {
  try {
    const url = elem.getAttribute('data-fpembed');
    fetch(url, {credentials: 'omit'})
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          var error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.html && elem && elem.parentNode) {
          const container = document.createElement('div');
          container.innerHTML = data.html;
          elem.parentNode.replaceChild(container, elem);
        }
      })
      .catch((err) => console.log(err));
  } catch (e) {
    console.log(e);
  }
}

function install() {
  try {
    Array.prototype.forEach.call(
      document.querySelectorAll('[data-fpembed]'),
      embed
    );
    const options = {
      childList: true,
      subtree: true,
    };
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes.length > 0) {
          Array.prototype.forEach.call(
            mutation.target.querySelectorAll('[data-fpembed]'),
            embed
          );
        }
      });
    });
    observer.observe(document.body, options);
  } catch (e) {
    console.log(e);
  }
}

if (typeof window !== 'undefined') {
  domready(install);
}
